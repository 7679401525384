
.colContainer {

}

.colContainer .col ul {
    list-style-type: circle;
}

.colContainer .col ul li {
    padding: 8px 0;
    font-weight: bold;
}