.subscribe-container {
  text-align: center;
  padding: 40px; /* Padding around the entire row */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; /* Apply the specified fonts */
}

.subscribe-heading {
  font-size: 1.75rem;
  margin-bottom: 10px; /* Adjust margin between heading and subheading */
}

.subscribe-subheading {
  font-size: 1.1rem;
  margin-bottom: 30px; /* Adjust space between the subheading and the boxes */
  color: #666; /* Lighter text color for the subheading */
}

.subscribe-tiers {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Reduce gap between boxes */
  padding: 20px; /* Padding around the entire row */
}

.subscribe-box-link {
  flex: 1;
  text-decoration: none; /* Remove underline from the entire box */
  color: inherit; /* Ensure link inherits the color of the box */
}

.subscribe-box {
  background-color: #f0f0f0;
  padding: 40px; /* Increase padding inside each box */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  border: 1px solid #ddd; /* Subtle solid border */
  min-width: 350px; /* Slightly wider for desktop */
  max-width: 550px; /* Maximum width for larger boxes */
  margin: auto; /* Center the boxes horizontally */
  color: #000; /* Set the color of the text to black */
  font-family: inherit; /* Inherit font for all elements */
}

.subscribe-box h3, .subscribe-box p, .subscribe-box ul li {
  text-decoration: none; /* Ensure no underline on the text */
  color: #000; /* All text inside the boxes will be black */
  font-family: inherit; /* Inherit font stack */
}

.subscribe-box h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.subscribe-box p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.subscribe-box .subheading { /* Subheading for w/ annual subscription */
  font-weight: 700; /* Slightly more font weight */
  color: #000; /* Make it black */
}

.subscribe-box ul {
  list-style-type: disc;
  padding-left: 0;
  list-style-position: inside; /* Ensure bullets stay with the text */
  text-align: left; /* Left align the bullet points */
}

.subscribe-box ul li {
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center; /* Center the list text without affecting bullet position */
}

/* Smaller scale effect on hover */
.subscribe-box:hover {
  transform: scale(1.02); /* Smaller scale effect */
  cursor: pointer; /* Show pointer to indicate it's clickable */
}

/* Mobile responsive: switch to one column */
@media only screen and (max-width: 768px) {
  .subscribe-tiers {
    flex-direction: column;
  }

  .subscribe-box {
    margin-bottom: 20px; /* Space between boxes on mobile */
    max-width: 100%; /* Make the boxes full-width on mobile */
    min-width: auto; /* Remove the minimum width on mobile */
  }
}
