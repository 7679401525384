.lessonsPage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px); /* Adjust based on your navbar and footer height */
  overflow: hidden; /* Prevent page scrolling */
}

.readerContent .para {font-size:16pt!important;}

table {
  border-collapse: collapse;
  width: 100%;
}



td, th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

tr:first-child {
  background-color: #f5f5f5;
}

tr:hover {
  background-color: #f9f9f9;
}

.readerContent .para {font-size:16pt;}

.lessonsPage img {
max-width: 80%;
min-width: 640px;
height: auto;
margin: 0 auto;
}

.readerContent .UrtAp {
  font-size:20px!important;
}


.readerContent ol {margin-top:5px!important;margin-bottom:5px!important;}

.readerContent .para span {
  font-size:20px!important;
}

#root > div > div > div.LessonsPage_content__iBVAM > div > div:nth-child(2) {
  font-size:20px!important;
}

.readerContent h2 {
  font-size:18pt!important;
  margin-bottom:25px!important;
}

.readerContent h1 {
  font-size:24pt;
}

.readerContent tbody {font-size:12pt!important;}

.readerContent td {border:solid!important;border-width:1px!important;padding:10px}

.readerContent p {
  font-size:14pt!important;
}

.readerContent div strong {font-size:14pt!important;}

.readerContent div strong tbody {font-size:14pt!important;}

.readerContent div {
  font-size:12pt!important;
}

.readerContent tbody div strong {
  font-size:14pt!important;
}

:global li div.list-bullet-todo-container {
  display: none;
}
:global li div.list-content {
  display: inline-block;
}

.mainContent {
  display: flex;
  flex-grow: 1;
  height: 100%; /* Ensure it takes the full available height */
}

.sidebar {
  min-width: 250px;
  background-color: #f0f0f0;
  padding: 1rem;
  overflow-y: auto;
  max-height: 100%; /* Sidebar will take the full height */
}

.content {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto; /* Make this section scrollable */
  min-width: 100px;
  max-height: 100%; /* Limit the height to the full viewport */
}

/* Other existing styles */

.searchForm {
  margin-bottom: 1rem;
}

.searchInput {
  width: 92%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.searchButton {
  width: 100%;
  padding: 0.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #45a049;
}

.lessonList {
  list-style-type: none;
  padding: 0;
}

.lessonList li {
  cursor: pointer;
  padding: 0.5rem 0;
  font-size: 15px;
}

.lessonList li:hover,
.lessonList li.selected {
  background-color: #e0e0e0;
}

.divider {
  border: 0;
  height: 1px;
  background-color: #ddd;
  margin: 0.5rem 0;
}

.goBackButton {
  margin-bottom: 1rem;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.goBackButton:hover {
  background-color: #e0e0e0;
}

.searchResult {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.searchResult h3 {
  margin-top: 0;
}

.searchResult button {
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.searchResult button:hover {
  background-color: #45a049;
}

.readerContent {
  user-select: none; /* Disable text selection */
  color: inherit; /* Inherit the color from parent */
}

.scrollDivider {
  width: 4px;
  background-color: #aaa;
  cursor: ew-resize;
  margin: 0 3px;
}

.categoryHeader {
  cursor: pointer;
}

/* Desktop */
.sidebar {
  width: 250px; /* Default sidebar width for desktop */
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.mainContent {
  display: flex;
}

/* Desktop */
.sidebar {
  width: 250px; /* Default sidebar width for desktop */
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.mainContent {
  display: flex;
}

/* Desktop */
.sidebar {
  width: 250px; /* Default sidebar width for desktop */
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.mainContent {
  display: flex;
}

/* Sidebar toggle button, hidden on desktop */
.sidebarToggle {
  display: none;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: -100%; /* Hidden by default */
    top: 0;
    bottom: 0;
    width: 250px; /* Adjust width as needed */
    background-color: #fff;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .sidebarOpen {
    left: 0; /* When sidebar is open, slide into view */
  }

  .mainContent {
    display: block; /* Stack content on mobile */
  }

  /* Styling for the toggle button */
  .sidebarToggle {
    display: block;
    position: fixed;
    left: 10px;
    bottom: 10px; /* Position at the bottom left */
    background-color: #333333; /* Background color */
    color: #ffffff; /* White arrow */
    border: none;
    cursor: pointer;
    padding: 10px;
    width: 50px; /* Set a fixed width */
    height: 50px; /* Set a fixed height to make it square */
    z-index: 1001;
    font-size: 20px; /* Larger arrow icon */
    text-align: center;
  }

  .sidebarToggle:hover {
    background-color: #555555; /* Slight hover effect */
  }
}

.para {font-size:16pt;}



:global .readerContent h1.noteTitle {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 32px;
  margin: 21px 0;
}

:global .readerContent .para {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  margin: 16px 0;
}

:global .readerContent .para strong span[data-fontsize="true"] {
  font-size: 16pt;
  font-weight: bold;
}

:global .readerContent .para span[data-fontsize="true"] {
  font-size: 11pt;
}

:global .readerContent table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

:global .readerContent table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  color: #333;
}

:global .readerContent img {
  max-width: 100%;
  height: auto;
  border-radius: 3px;
}

.readerContent li div.list-bullet-todo-container {display:none!important;}

.readerContent div.list-bullet-todo-container {display:none!important;}

.readerContent .list-bullet-todo-container {display:none!important;}

.lessonsPage .list-bullet-todo-container {display:none!important;}

.readerContent div.list-bullet-todo-container {display:none!important;}

.lessonsPage div.list-bullet-todo-container {display:none!important;}

#LessonsPage_mainContent__QKVXO > div.LessonsPage_content__iBVAM > div > ol:nth-child(7) > li:nth-child(1) > div.list-bullet-todo-container {display:none!important;}

.list-bullet-todo {display:none!important;}

#LessonsPage_mainContent__QKVXO > div.LessonsPage_content__iBVAM > div > ol:nth-child(7) > li:nth-child(1) > div.list-bullet-todo-container > input {display:none!important;}

